import axios from 'axios'
import {
	Message
} from 'element-ui'
import store from '@/store'
import {
	getToken
} from '@/utils/auth'
import router from '../router'

// 创建axios实例
const service = axios.create({
	// baseURL: 'https://test.hngegejk.cn:4443/', // url = base url + request url
	baseURL: process.env.VUE_APP_BASE_API,
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 5000 // request timeout
})

console.log('请求地址： ',process.env.VUE_APP_BASE_API)

// request拦截器,通过token发起请求
service.interceptors.request.use(
	config => {
		// console.log(config, 777)
		if (store.getters.token) {
			config.headers['Authorization'] = 'Bearer ' + getToken()
		}
		if (config.method === 'get') {
			for (let key in config.params) {
				if (config.params[key] === '') {
					delete config.params[key]
				}
			}
		}
		return config
	},
	error => {
		// 返回错误信息
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// respone拦截器,定义返回数据格式
service.interceptors.response.use(
	response => {
		// 打印出后端返回的结果，方便调试
		// console.log('返回第一步：拦截器第一手获取服务器返回信息')
		// console.log(response)
		// console.log('返回第一步：拦截器第一手获取服务器返回信息 end')
		return response.data
	},
	error => {
		// 定义几种错误返回值
		if (error.response.status === 401) {
			store.dispatch('user/resetToken').then(() => {
				// router.push({ path: '/login' })
				// Message({
				//   message: '账号密码错误',
				//   type: 'error',
				//   duration: 5 * 1000
				// })
			})
		} else if (error.response.status === 403) {
			Message({
				message: '权限拒绝',
				type: 'error'
			})
		} else if (error.response.status === 422) {
			// Message({
			//   message: error.response.data.message,
			//   type: 'error'
			// })
		} else if (error.response.status === 500) {
			let msg = '服务器内部错误'
			if (error.response.data.message) {
				msg = error.response.data.message
			}
			Message({
				message: msg,
				type: 'error'
			})
		}
		return Promise.reject(error)
	}
)

export default service
