import request from '@/utils/request'

// 系统管理 - 菜单管理 - 获取所有菜单列表通过cate_id 区分
export function getMenuManageInternetHospitalList (params) {
  return request({
    url: '/admin/base/menu/index',
    method: 'GET',
    params
  })
}



// 系统管理 - 父级(下拉框数据, 树形结构)
export function getBaseMenuDropDownList (params) {
  return request({
    url: '/admin/base/menu/dropDownList',
    method: 'GET',
    params
  })
}

// 系统管理 - 创建/追加 菜单
export function postBaseMenuStore (data) {
  return request({
    url: '/admin/base/menu/store',
    method: 'POST',
    data
  })
}

// 系统管理 - 删除菜单
export function delBaseMenuDelete (params) {
  return request({
    url: '/admin/base/menu/delete',
    method: 'GET',
    params
  })
}

// 系统管理 - 修改菜单
export function putBaseMenuUpdate (data) {
  return request({
    url: '/admin/base/menu/update',
    method: 'PUT',
    data
  })
}

// 系统管理 启用/禁用 switch按钮
export function putBaseMenuExamine (data) {
  return request({
    url: '/admin/base/menu/examine',
    method: 'PUT',
    data
  })
}

// 系统管理 获取菜单分类列表
export function getBaseCateMenuList (params) {
  return request({
    url: '/admin/base/cateMenu/index',
    method: 'GET',
    params
  })
}

// 系统管理 创建菜单分类列表
export function postBaseCateMenuStore (data) {
  return request({
    url: '/admin/base/cateMenu/store',
    method: 'POST',
    data
  })
}

// 系统管理 删除菜单分类列表数据
export function delBaseCateMenuDelete (params) {
  return request({
    url: '/admin/base/cateMenu/delete',
    method: 'GET',
    params
  })
}

// 系统管理 修改菜单分类列表数据
export function putBaseCateMenuUpdate (data) {
  return request({
    url: '/admin/base/cateMenu/update',
    method: 'PUT',
    data
  })
}

// 系统管理 禁用或启用分类
export function putBaseCateMenuExamine (data) {
  return request({
    url: '/admin/base/cateMenu/examine',
    method: 'PUT',
    data
  })
}

// --------------------------------------------

// 系统管理 公告管理 获取公告消息列表
export function getNoticeList(params) {
  return request({
    url: '/admin/notice',
    method: 'GET',
    params
  })
}

// 系统管理 公告管理 增加通告

export function postNotice(data) {
  return request({
    url: `/admin/notice`,
    method: 'POST',
    data
  })
}

// 系统管理 公告管理 发布公告消息
export function postNoticePublish(id) {
  return request({
    url: `/admin/notice/publish/${id}`,
    method: 'POST',
  })
}

// 系统管理 公告管理 删除公告
export function delNotice(id) {
  return request({
    url: `/admin/notice/${id}`,
    method: 'DELETE',
  })
}

// 系统管理 公告管理 修改公告
export function putNotice(data) {
  return request({
    url: `/admin/notice/${data.id}`,
    method: 'PUT',
    data
  })
}


// 系统管理 获取消息列表
export function getMessageList(params) {
  return request({
    url: '/admin/message',
    method: 'GET',
    params
  })
}


// 系统管理 未读消息统计数,铃铛显示
export function getMessageCount(params) {
  return request({
    url: '/admin/message/count',
    method: 'GET',
    params
  })
}

// 系统管理 消息(铃铛) 消息详情
export function getMessageInfo(id) {
  return request({
    url: `/admin/message/info/${id}`,
    method: 'GET',
  })
}

// 系统管理 消息(铃铛) 未读消息标记已读
export function postMessageRead(id) {
  return request({
    url: `/admin/message/read/${id}`,
    method: 'POST',
  })
}

// 系统管理 消息(铃铛) 删除消息
export function delMessageDelMessage(id) {
  return request({
    url: `/admin/message/delMessage/${id}`,
    method: 'DELETE',
  })
}

// 系统管理 消息(铃铛) 删除所有已读消息
export function delMessageDelAllRead() {
  return request({
    url: '/admin/message/delAllRead',
    method: 'DELETE',
  })
}

// 系统管理 消息(铃铛) 所有未读消息标记已读
export function postMessageDelAllRead() {
  return request({
    url: '/admin/message/readAll',
    method: 'POST',
  })
}
