import request from '@/utils/request'

//一级分类列表
export function getParentCate(params) {
    return request({
        url: '/admin/parent-cate',
        method: 'GET',
        params
    })
}
//通过一级分类查找二级分类

export function getChildCate(params) {
    return request({
        url: '/admin/child-cate',
        method: 'GET',
        params
    })
}
//获取药房列表
export function getPharmacyList(params) {
    return request({
        url: '/admin/pharmacy-list',
        method: 'GET',
        params
    })
}
//获取运费模板下拉
export function getExpressSelect(params) {
    return request({
        url: '/admin/express-select',
        method: 'GET',
        params
    })
}
//商品下拉选择
export function getGoodsSelect(params) {
    return request({
        url: '/admin/goods-select',
        method: 'GET',
        params
    })
}
//通过商品id获取规格下拉
export function getGoodsAttr(params) {
    return request({
        url: '/admin/goods-attr/'+params.id,
        method: 'GET',
        params
    })
}
//更新数据
export function getOptionsCache() {
    return request({
        url: '/admin/getOptionsCache',
        method: 'GET',
    })
}
