<template>
	<div class="navbar">
		<hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
							 @toggleClick="toggleSideBar"/>
		
		<!--<breadcrumb id="breadcrumb-container" class="breadcrumb-container"/>-->
		<menuBar class="hamburger-container"/>
		<!--<navbarTitles class="navbar-titles-container"/>-->
		
		<div class="right-menu">
			
			<notification class="right-menu-item"/>
			
			<el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
				<div class="avatar-wrapper">
					<img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar">
					<span class="user-name">{{name}}</span>
					<i class="el-icon-caret-bottom"/>
				</div>
				<el-dropdown-menu slot="dropdown">
<!--					<router-link :to="{path:'/authManage/userBasicInfoEdit',query: {flag: true}}">
						<el-dropdown-item>
							<div class="dropdown-item">
								<i class="el-icon-user-solid"></i>
								<span class="title">基本信息</span>
							</div>
						</el-dropdown-item>
					</router-link>-->

					<el-dropdown-item>
						<div class="dropdown-item">
							<i class="el-icon-edit"></i>
							<span class="title" @click="changePasswordDialogFormVisible = true">修改密码</span>
						</div>
					</el-dropdown-item>

					<el-dropdown-item>
						<div class="dropdown-item">
							<i class="el-icon-question"></i>
							<span class="title">帮助中心</span>
						</div>
					</el-dropdown-item>
					<el-dropdown-item>
						<div class="dropdown-item" @click="refreshData">
							<i class="iconfont icon-QCHC" style="margin-top: -2px"></i>
							<span class="title">清除缓存</span>
						</div>
					</el-dropdown-item>
					<el-dropdown-item divided @click.native="logout">
						<div class="dropdown-item">
							<i class="iconfont icon-tuichu" style="font-size: 12px"></i>
							<span class="title">退出登录</span>
						</div>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		
		<!--账号密码修改弹出对话框-->
		<el-dialog title="" :visible.sync="changePasswordDialogFormVisible" append-to-body	 width="480px">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">账号密码</span>
			<el-form :model="addForm" :rules="rules"  size="mini" ref="ruleForm">
				<el-form-item label="登入名称" label-width="80px" prop="name">
					<el-input v-model="name" disabled></el-input>
				</el-form-item>
				<div style="margin-top: -16px; margin-left: 80px;">
					<i class="el-icon-warning" style="margin-right: 3px;color: orange"></i><span style="color: #777;font-size: 12px">账号创建后不能更改</span>
				</div>
				<el-form-item label="新密码" label-width="80px" prop="changePasswordValue">
					<el-input type="password" v-model="addForm.changePasswordValue" autocomplete="off" show-password clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="mini" @click="handleCancel('ruleForm')">取 消</el-button>
				<el-button size="mini" type="primary" @click="handleConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
		
		
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import notification from '@/layout/components/Notification'
import navbarTitles from '@/layout/components/NavbarTitles'
import menuBar from '@/layout/components/MenuBar'
import {getOptionsCache} from '@/newApi/commit'
import {putMemberUPassword} from '@/api/authManage'
export default {
  components: {
    // Breadcrumb,
    Hamburger,
    notification,
    menuBar
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'name',
      'device',
      'adminId'
    ])
  },
	data() {
    return {
      changePasswordDialogFormVisible: false,
      addForm: {
        name: '',
        changePasswordValue: ''
			},
      rules: {
        changePasswordValue: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
      }
		}
	},
  methods: {
	//更新数据
	refreshData(){
		console.log('更新数据')
		getOptionsCache().then(res=>{
			console.log(res.data)
			//存储到本地
			localStorage.setItem('SET_TABLE_OPTIONS',JSON.stringify(res.data.table_options))
			//刷新页面
			window.location.reload()
		})
	},
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
		_putMemberUPassword(params) {
      putMemberUPassword(params).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
					this.handleCancel("ruleForm")
				}
			}).catch((error) => {
        console.log(error)
        this.$message({
          type: 'error',
          message: error.message
        });
			})
		},
		
    handleCancel(formName) {
      this.$refs[formName].resetFields()
			this.changePasswordDialogFormVisible = false
		},
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            admin_id: this.adminId,
            password: this.addForm.changePasswordValue
					}
					this._putMemberUPassword(params)
        } else {
          console.log('error submit!!');
          return false;
        }
      })
		},
		
    async logout () {
      await this.$store.dispatch('user/logout')
      await this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
	.navbar {
		height: 50px;
		overflow: hidden;
		position: relative;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
		
		.hamburger-container {
			line-height: 46px;
			height: 100%;
			float: left;
			cursor: pointer;
			transition: background .3s;
			-webkit-tap-highlight-color: transparent;
			
			&:hover {
				background: rgba(0, 0, 0, .025)
			}
		}
		
		.breadcrumb-container {
			float: left;
		}
		
		
		.right-menu {
			float: right;
			height: 100%;
			line-height: 50px;
			
			&:focus {
				outline: none;
			}
			
			.right-menu-item {
				display: inline-block;
				padding: 0 8px;
				height: 100%;
				font-size: 18px;
				color: #5a5e66;
				vertical-align: text-bottom;
				
				&.hover-effect {
					cursor: pointer;
					transition: background .3s;
					
					&:hover {
						background: rgba(0, 0, 0, .025)
					}
				}
			}
			
			.avatar-container {
				margin-right: 60px;
				
				.avatar-wrapper {
					margin-top: 5px;
					display: flex;
					position: relative;
					
					.user-avatar {
						cursor: pointer;
						width: 40px;
						height: 40px;
						border-radius: 50%;
					}
					
					.user-name {
						margin-left: 10px;
						margin-top: -3px;
						cursor: pointer;
						text-align: center;
						&:hover{
							color: #2362FB;
						}
					}
					
					.el-icon-caret-bottom {
						cursor: pointer;
						position: absolute;
						right: -20px;
						top: 18px;
						font-size: 12px;
						&:hover{
							color: #2362FB;
						}
					}
				}
			}
		}
	}
	
	/*图像下拉框样式调整*/
	.dropdown-item{
		display: flex;
		justify-content: space-around;
		align-items: center;
		color: #777;
		&:hover{
			color: #2362FB;
		}
	}
	/deep/ .el-dropdown-menu__item{
		width: 130px !important;
	}
</style>
