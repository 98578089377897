import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'


// // 当设置 true 的时候该路由不会在侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
// hidden: true // (默认 false)
//
// //当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
// redirect: 'noRedirect'
//
// // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
// // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
// // 若你想不管路由下面的 children 声明的个数都显示你的根路由
// // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
// alwaysShow: true
//
// name: 'router-name' // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
// meta: {
//     roles: ['admin', 'editor'] // 设置该路由进入的权限，支持多个权限叠加
//     title: 'title' // 设置该路由在侧边栏和面包屑中展示的名字
//     icon: 'svg-name' // 设置该路由的图标，支持 svg-class，也支持 el-icon-x element-ui 的 icon
//     noCache: true // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
//     breadcrumb: false //  如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
//     affix: true // 如果设置为true，它则会固定在tags-view中(默认 false)
//
//     // 当路由设置了该属性，则会高亮相对应的侧边栏。
//     // 这在某些场景非常有用，比如：一个文章的列表页路由为：/article/list
//     // 点击文章进入文章详情页，这时候路由为/article/1，但你想在侧边栏高亮文章列表的路由，就可以进行如下设置
//     activeMenu: '/article/list'
// }

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [{
	path: '/login',
	component: () =>
		import('@/views/login/index'),
	hidden: true
},
{
	path: '/auth-redirect',
	component: () =>
		import('@/views/login/auth-redirect'),
	hidden: true
},
	// {
	//   name: 'dashboard',
	//   path: '/',
	//   component: Layout,
	//   redirect: '/dashboard',
	//   children: [{
	//     path: 'dashboard',
	//     name: 'dashboard',
	//     component: () =>
	//       import('@/views/dashboard/index'),
	//     meta: {
	//       title: '首页',
	//       icon: 'el-icon-s-home'
	//     }
	//   }]
	// },
]
export const asyncRoutes = [{
	name: 'dashboard',
	path: '/',
	component: Layout,
	redirect: '/dashboard',
	children: [{
		path: '/dashboard',
		name: 'dashboard',
		component: () =>
			import('@/views/SHOPHomePage/SHOPHomePage'),
		meta: {
			title: '首页',
			icon: 'el-icon-s-home'
		}
	}]
},

{
	name: 'shopMemberManage',
	path: '/shopMemberManage',
	component: Layout,
	redirect: '/shopMemberManage/memberList',
	meta: {
		title: '会员管理',
		roles: ['admin'],
		icon: "el-icon-s-custom"
	},
	children: [{
		path: 'memberList',
		component: () =>
			import('@/views/shopMemberManage/memberList'), // Parent router-view
		name: 'memberList',
		meta: {
			title: '会员列表',
			roles: ['admin'],
		},
	}, {
		path: 'memberInterests',
		component: () =>
			import('@/views/shopMemberManage/memberInterests'), // Parent router-view
		name: 'memberInterests',
		meta: {
			title: '会员权益',
			roles: ['admin'],
		},
	}
	]
}, {
	name: 'shopGoodsManage',
	path: '/shopGoodsManage',
	component: Layout,
	redirect: '/shopGoodsManage/shopMemberManage',
	meta: {
		title: '商品管理',
		roles: ['admin'],
		icon: "el-icon-s-goods"
	},
	children: [{
		path: 'goodsClassify',
		component: () =>
			import('@/views/shopGoodsManage/goodsClassify'), // Parent router-view
		name: 'goodsClassify',
		meta: {
			title: '商品分类',
			roles: ['admin'],
		},
	}, {
		path: 'goodsList',
		component: () =>
			import('@/views/shopGoodsManage/goodsList'), // Parent router-view
		name: 'goodsList',
		meta: {
			title: '商品列表',
			roles: ['admin'],
		},
	}, {
		path: 'goodsSetMeal',
		component: () =>
			import('@/views/shopGoodsManage/goodsSetMeal'), // Parent router-view
		name: 'goodsSetMeal',
		meta: {
			title: '商品套餐',
			roles: ['admin'],
		},
	}
	]
}, {
	name: 'shopOrderMamage',
	path: '/shopOrderMamage',
	component: Layout,
	redirect: '/shopOrderMamage/shopOrderMamage',
	meta: {
		title: '订单管理',
		roles: ['admin'],
		icon: "el-icon-s-order"
	},
	children: [{
		path: 'orderList',
		component: () =>
			import('@/views/shopOrderMamage/orderList'), // Parent router-view
		name: 'orderList',
		meta: {
			title: '订单列表',
			roles: ['admin'],
		},
	}, {
		path: 'shipmentsList',
		component: () =>
			import('@/views/shopOrderMamage/shipmentsList'), // Parent router-view
		name: 'shipmentsList',
		meta: {
			title: '订单发货',
			roles: ['admin'],
		},
	}, {
		path: 'afterList',
		component: () =>
			import('@/views/shopOrderMamage/afterList'), // Parent router-view
		name: 'afterList',
		meta: {
			title: '售后订单',
			roles: ['admin'],
		},
	}, {
		path: 'orderInfo',
		component: () =>
			import('@/views/shopOrderMamage/orderInfo'), // Parent router-view
		name: 'orderInfo',
		meta: {
			title: '订单详情'
		},
		hidden: true
	}, {
		path: 'afterInfo',
		component: () =>
			import('@/views/shopOrderMamage/afterInfo'), // Parent router-view
		name: 'afterInfo',
		meta: {
			title: '售后详情'
		},
		hidden: true
	}
	]
}, {
	name: 'shopEvaluateManage',
	path: '/shopEvaluateManage',
	component: Layout,
	redirect: '/shopEvaluateManage/shopEvaluateManage',
	meta: {
		title: '评价管理',
		roles: ['admin'],
		icon: "el-icon-edit-outline"
	},
	alwaysShow: true,
	children: [{
		path: 'shopEvaluateList',
		component: () =>
			import('@/views/shopEvaluateManage/shopEvaluateList'), // Parent router-view
		name: 'shopEvaluateList',
		meta: {
			title: '评价列表',
			roles: ['admin'],
		},
	}
	]
},
{
	name: 'article',
	path: '/article',
	component: Layout,
	redirect: '/article/articleInfo',
	meta: {
		title: '健康百科',
		roles: ['admin'],
		icon: "el-icon-s-custom"
	},
	children: [{
		path: 'articleCate',
		component: () =>
			import('@/views/article/articleCate'), // Parent router-view
		name: 'memberList',
		meta: {
			title: '百科分类',
			roles: ['admin'],
		},
	}, {
		path: 'articleList',
		component: () =>
			import('@/views/article/articleList'), // Parent router-view
		name: 'articleList',
		meta: {
			title: '百科文章',
			roles: ['admin'],
		},
	}
	]
}, 
{
	name: 'shopMarketManage',
	path: '/shopMarketManage',
	component: Layout,
	redirect: '/shopMarketManage/shopMarketManage',
	meta: {
		title: '营销管理',
		roles: ['admin'],
		icon: "el-icon-s-finance"
	},
	children: [{
		path: 'marketList',
		component: () =>
			import('@/views/shopMarketManage/marketList'), // Parent router-view
		name: 'marketList',
		meta: {
			title: 'banner列表',
			roles: ['admin'],
		},
	}, {
		path: 'discountList',
		component: () =>
			import('@/views/shopMarketManage/discountList'), // Parent router-view
		name: 'discountList',
		meta: {
			title: '优惠券列表',
			roles: ['admin'],
		},
	}, {
		path: 'fullMinusActivity',
		component: () =>
			import('@/views/shopMarketManage/fullMinusActivity'), // Parent router-view
		name: 'fullMinusActivity',
		meta: {
			title: '满减活动',
			roles: ['admin'],
		},
	}, {
		path: 'seckillActivity',
		component: () =>
			import('@/views/shopMarketManage/seckillActivity'), // Parent router-view
		name: 'seckillActivity',
		meta: {
			title: '秒杀活动',
			roles: ['admin'],
		},
	}
	]
}, {
	name: 'shopStoreSet',
	path: '/shopStoreSet',
	component: Layout,
	redirect: '/shopStoreSet/shopStoreSet',
	meta: {
		title: '商城设置',
		roles: ['admin'],
		icon: "el-icon-s-shop"
	},
	children: [{
		path: 'templateFreight',
		component: () =>
			import('@/views/shopStoreSet/templateFreight'), // Parent router-view
		name: 'templateFreight',
		meta: {
			title: '运费模板',
			roles: ['admin'],
		},
	}, {
		path: 'orderSet',
		component: () =>
			import('@/views/shopStoreSet/orderSet'), // Parent router-view
		name: 'orderSet',
		meta: {
			title: '订单设置',
			roles: ['admin'],
		},
	},
	{
		path: 'integralRule',
		component: () =>
			import('@/views/shopStoreSet/integralRule'), // Parent router-view
		name: 'integralRule',
		meta: {
			title: '积分规则',
			roles: ['admin'],
		},
	}
	]
}, {
	name: 'shopFinancialManage',
	path: '/shopFinancialManage',
	component: Layout,
	redirect: '/shopFinancialManage/shopFinancialManage',
	meta: {
		title: '财务管理',
		roles: ['admin'],
		icon: "el-icon-s-marketing"
	},
	children: [{
		path: 'financialManage',
		component: () =>
			import('@/views/shopFinancialManage/financialManage'), // Parent router-view
		name: 'financialManage',
		meta: {
			title: '交易明细',
			roles: ['admin'],
		},
	},{
		path: 'dealList',
		component: () =>
			import('@/views/shopFinancialManage/dealList'), // Parent router-view
		name: 'dealList',
		meta: {
			title: '提现明细',
			roles: ['admin'],
		},
	}
	]
},{
	name: 'shopPromotionManage',
	path: '/shopPromotionManage',
	component: Layout,
	redirect: '/shopPromotionManage/shopPromotionManage',
	meta: {
		title: '推广管理',
		roles: ['admin'],
		icon: "el-icon-s-help"
	},
	alwaysShow: true,
	children: [{
		path: 'promotionList',
		component: () =>
			import('@/views/shopPromotionManage/promotionList'), // Parent router-view
		name: 'promotionList',
		meta: {
			title: '推广商品',
			roles: ['admin'],
		},
	}
	]
},
	{
		name:'shopSystemManage',
		path:'/shopSystemManage',
		component:Layout,
		redirect:'/shopSystemManage/shopSystemManage',
		meta:{
			title:'系统管理',
			roles:['admin'],
			icon:"el-icon-s-tools"
		},
		alwaysShow: true,
		children:[{
			path:'versionsList',
			component:() => import('@/views/shopSystemManage/versionsList'),
			name:'versionsList',
			meta:{
				title:'版本管理',
				roles:['admin'],

			}
		}]
	}
// {
// 	name: 'order',
// 	path: '/order',
// 	component: Layout,
// 	redirect: '/order/order',
// 	meta: {
// 		title: '订单管理',
// 		roles: ['admin'],
//   icon: "el-icon-s-help"
// 	},
// 	children: [{
// 		path: 'OrderMananger',
// 		component: () =>
// 			import('@/views/SHOPOrder/otherOrder'), // Parent router-view
// 		name: 'OrderMananger',
// 		meta: {
// 			title: '订单管理',
// 			roles: ['admin'],
// 		},
// 	},{
// 			path: 'customerOrder',
// 			component: () =>
// 				import('@/views/SHOPOrder/customerOrder'), // Parent router-view
// 			name: 'customerOrder',
// 			meta: {
// 				title: '客情订单',
// 				roles: ['admin'],
// 			},
// 		},
//   {
// 	path: 'hospitalOrder',
// 	component: () =>
// 	  import('@/views/SHOPOrder/hospitalOrder'), // Parent router-view
// 	name: 'hospitalOrder',
// 	meta: {
// 	  title: '处方订单',
// 	  roles: ['admin'],

// 	},
//   },
//   {
// 	path: 'shopOrder',
// 	component: () =>
// 	  import('@/views/SHOPOrder/shopOrder'), // Parent router-view
// 	name: 'shopOrder',
// 	meta: {
// 	  title: '商城订单',
// 	  roles: ['admin'],
// 	},
//   },
//   {
// 	path: 'orderManagerCustomerCDM',
// 	component: () =>
// 	  import('@/views/SHOPOrder/orderManagerCustomerCDM'), // Parent router-view
// 	name: 'shopOrder',
// 	meta: {
// 	  title: '订单管理客情',
// 	  roles: ['admin'],
// 	},
//   },
// 		// {
// 		// 	path: 'drugOrder',
// 		// 	component: () =>
// 		// 		import('@/views/SHOPOrder/drugOrder'), // Parent router-view
// 		// 	name: 'drugOrder',
// 		// 	meta: {
// 		// 		title: '处方订单'
// 		// 	},
// 		// },
// 		{
// 			path: 'orderDetail',
// 			component: () =>
// 				import('@/views/SHOPOrder/orderDetail'), // Parent router-view
// 			name: 'orderDetail',
// 			meta: {
// 				title: '订单详情'
// 			},
// 			hidden: true
// 		},
// 	]
// }
	//   {
	//     path: 'shopOrder',
	//     component: () =>
	//       import('@/views/SHOPOrder/shopOrder'), // Parent router-view
	//     name: 'shopOrder',
	//     meta: {
	//       title: '商城订单',
	//       roles: ['admin'],
	//     },
	//   },
	//   {
	//     path: 'orderManagerCustomerCDM',
	//     component: () =>
	//       import('@/views/SHOPOrder/orderManagerCustomerCDM'), // Parent router-view
	//     name: 'shopOrder',
	//     meta: {
	//       title: '订单管理客情',
	//       roles: ['admin'],
	//     },
	//   },
	// 		// {
	// 		// 	path: 'drugOrder',
	// 		// 	component: () =>
	// 		// 		import('@/views/SHOPOrder/drugOrder'), // Parent router-view
	// 		// 	name: 'drugOrder',
	// 		// 	meta: {
	// 		// 		title: '处方订单'
	// 		// 	},
	// 		// },
	// 		{
	// 			path: 'orderDetail',
	// 			component: () =>
	// 				import('@/views/SHOPOrder/orderDetail'), // Parent router-view
	// 			name: 'orderDetail',
	// 			meta: {
	// 				title: '订单详情'
	// 			},
	// 			hidden: true
	// 		},
	// 	]
	// },

	// {
	// 	path: '/pharmacyManage',
	// 	component: Layout,
	// 	redirect: '/pharmacyManage/storeList',
	// 	name: 'pharmacyManage',
	// 	meta: {
	// 		title: '药房管理',
	// 		roles: ['admin', 'pharmacy'],
	// 		icon: 'el-icon-s-order'
	// 	},
	// 	children: [
	// 		{
	// 			path: 'pharmacyList',
	// 			component: () =>
	// 				import('@/views/SHOPPharmacyManage/pharmacyList'), // Parent router-view
	// 			name: 'pharmacyList',
	// 			meta: {
	// 				title: '药房管理',
	// 				roles: ['admin']
	// 			},
	// 		},
	// 		//   {
	// 		// 	path: 'orderReview',
	// 		// 	component: () =>
	// 		// 	  import('@/views/pharmacyManage/orderReview'), // Parent router-view
	// 		// 	name: 'orderReview',
	// 		// 	meta: {
	// 		// 	  title: '订单审核',
	// 		// 	  roles: ['admin']
	// 		// 	},
	// 		//   },
	// 		//   {
	// 		// 	path: 'pharmacyOrderList',
	// 		// 	component: () => import('@/views/pharmacyManage/pharmacyOrderList'), // Parent router-view
	// 		// 	name: 'pharmacyOrderList',
	// 		// 	meta: {
	// 		// 	  title: '订单管理',
	// 		// 	  roles: ['admin']
	// 		// 	},
	// 		//   },
	// 		{
	// 			path: 'myPharmacy',
	// 			component: () => import('@/views/SHOPPharmacyManage/addPharmacy'), // Parent router-view
	// 			name: 'myPharmacy',
	// 			meta: {
	// 				title: '药房信息',
	// 				roles: ['pharmacy', 'admin']
	// 			},
	// 			admin_hidden: true
	// 			// hidden: true   //是否显示在菜单
	// 		},
	// 		{
	// 			path: 'addPharmacy',
	// 			component: () =>
	// 				import('@/views/SHOPPharmacyManage/addPharmacy'), // Parent router-view
	// 			name: 'addPharmacy',
	// 			meta: {
	// 				title: '新增药房',
	// 				roles: ['admin']
	// 			},
	// 			hidden: true
	// 		},
	// 		{
	// 			path: 'myPharmacy',
	// 			component: () =>
	// 				import('@/views/SHOPPharmacyManage/addPharmacy'), // Parent router-view
	// 			name: 'myPharmacy',
	// 			admin_hidden: true,
	// 			meta: {
	// 				title: '药房信息',
	// 				roles: ['pharmacy']
	// 			},
	// 		},
	// 		{
	// 			path: 'pharmacyDrugList',
	// 			component: () =>
	// 				import('@/views/SHOPPharmacyManage/pharmacyDrugList'), // Parent router-view
	// 			name: 'pharmacyDrugList',
	// 			meta: {
	// 				title: '药品维护',
	// 				roles: ['admin']
	// 			},
	// 			hidden: true //是否显示在菜单
	// 		},
	// 		//药房端显示
	// 		{
	// 			path: 'pharmacyDrugLists',
	// 			component: () =>
	// 				import('@/views/SHOPPharmacyManage/pharmacyDrugList'), // Parent router-view
	// 			name: 'pharmacyDrugLists',
	// 			meta: {
	// 				title: '药品维护',
	// 				roles: ['admin']
	// 			},
	// 			admin_hidden: true,
	// 			// hidden: true //是否显示在菜单
	// 		},
	// 		//   {
	// 		// 	path: 'pharmacyOrderDetail',
	// 		// 	component: () =>
	// 		// 	  import('@/views/pharmacyManage/pharmacyOrderDetail'), // Parent router-view
	// 		// 	name: 'pharmacyOrderDetail',
	// 		// 	meta: {
	// 		// 	  title: '订单详情',
	// 		// 	  roles: ['pharmacy']
	// 		// 	},
	// 		// 	hidden: true //是否显示在菜单
	// 		//   },
	// 	]
	// },

	//   {
	//   	path: '/drugManage',
	//   	name: 'drugManage',
	//   	component: Layout,
	//   	redirect: 'noRedirect',
	//   	meta: {
	//   		title: '商品模块',
	//   		roles: ['admin'],
	//   		icon: "el-icon-s-management"
	//   	},
	//   	children: [
	//   		{
	//   			path: 'drugLabelManage',
	//   			name: 'drugLabelManage',
	//   			component: () =>
	//   				import('@/views/SHOPDrugManage/drugLabelManage'),
	//   			meta: {
	//   				title: '标签管理',
	//   				roles: ['admin']
	//   			}
	//   		},
	//   		{
	//   			path: 'drugManageChild',
	//   			name: 'drugManageChild',
	//   			component: () =>
	//   				import('@/views/SHOPDrugManage/drugManage'),

	//   			meta: {
	//   				title: '商品管理',
	//   				roles: ['admin']
	//   			}
	//   		},
	//   		{
	//   			path: 'drugAudit',
	//   			name: 'drugAudit',
	//   			component: () =>
	//   				import('@/views/SHOPDrugManage/drugAudit'),

	//   			meta: {
	//   				title: '商品审核',
	//   				roles: ['admin']
	//   			}
	//   		},
	//       {
	//         path: 'drugEditDetail',
	//         name: 'drugEditDetail',
	//         hidden: true,
	//         component: () =>
	//           import('@/views/SHOPDrugManage/drugEditDetail'),
	//         meta: {
	//           title: '编辑药品详情',
	//           roles: ['admin']
	//         }
	//       }
	//   	]
	//   },


	// 	{
	// 		name: 'systemBasic',
	// 		path: '/systemBasic',
	// 		component: Layout,
	// 		redirect: 'noRedirect',
	// 		meta: {
	// 			title: '系统基础',
	// 			roles: ['admin', 'pharmacy'],
	// 			icon: 'el-icon-s-tools'
	// 		},
	// 		children: [{
	// 				path: 'orderLog',
	// 				component: () =>
	// 					import('@/views/SHOPSystemBasic/orderLog'), // Parent router-view
	// 				name: 'orderLog',
	// 				meta: {
	// 					title: '订单日志',
	// 					roles: ['admin'],
	// 				},
	// 			},
	// 			{
	// 				path: 'exception',
	// 				component: () =>
	// 					import('@/views/SHOPSystemBasic/exception'), // Parent router-view
	// 				name: 'exception',
	// 				meta: {
	// 					title: '异常监控',
	// 					roles: ['admin'],
	// 				},
	// 			},
	// 		]
	// 	},

	//   {
	//     name: 'workingManage',
	//     path: '/workingManage',
	//     component: Layout,
	//     redirect: 'noRedirect',
	//     meta: {
	//       title: '运营管理',
	//       roles: ['admin'],
	//       icon: 'el-icon-collection'
	//     },
	//     children: [{
	//       path: 'homeSlideshow',
	//       component: () =>
	//         import('@/views/ShopWorkingManage/homeSlideshow'), // Parent router-view
	//       name: 'homeSlideshow',
	//       meta: {
	//         title: '首页轮播图',
	//         roles: ['admin'],
	//       },
	//     },
	//       {
	//       path: 'coupon',
	//       component: () =>
	//         import('@/views/ShopWorkingManage/coupon'), // Parent router-view
	//       name: 'coupon',
	//       meta: {
	//         title: '优惠券',
	//         roles: ['admin'],
	//       },
	//     },
	//       {
	//       path: 'recommendProduct',
	//       component: () =>
	//         import('@/views/ShopWorkingManage/recommendProduct'), // Parent router-view
	//       name: 'recommendProduct',
	//       meta: {
	//         title: '首页推荐商品',
	//         roles: ['admin'],
	//       },
	//     },{
	// 		path: 'tc',
	// 		component: () =>
	// 		  import('@/views/SHOPTc/tc'), // Parent router-view
	// 		name: 'tc',
	// 		meta: {
	// 		  title: '套餐管理',
	// 		  roles: ['admin'],
	// 		},
	// 	  },]
	//   },
		,
		{
			path: '/auth',
			name: 'auth',
			component: Layout,
			redirect: 'noRedirect',
			meta: {
				title: '权限管理',
				roles: ['admin'],
				icon: "el-icon-lock"
			},
			children: [{
				path: 'user',
				name: 'user',
				component: () =>
					import('@/views/accountAuth/account'),
				meta: {
					title: '账号管理',
					roles: ['admin']
				}
			}, {
				path: 'role',
				name: 'role',
				component: () =>
					import('@/views/accountAuth/role'),
				meta: {
					title: '角色管理',
					roles: ['admin'],
				}
			}, {
				path: 'permission',
				name: 'permission',
				component: () =>
					import('@/views/accountAuth/auth'),
				meta: {
					title: '权限管理'
				}
			}]
		},
	// 	{
	// 		path: '/finance',
	// 		name: 'finance',
	// 		component: Layout,
	// 		redirect: 'noRedirect',
	// 		meta: {
	// 			title: '财务管理',
	// 			roles: ['admin'],
	// 			icon: "el-icon-money"
	// 		},
	// 		children: [{
	// 			path: 'pay',
	// 			name: 'pay',
	// 			component: () =>
	// 				import('@/views/SHOPFinance/pay'),

	// 			meta: {
	// 				title: '交易记录',
	// 				roles: ['admin']
	// 			}
	// 		},
	//       {
	//         path: 'dealReport',
	//         name: 'dealReport',
	//         component: () =>
	//           import('@/views/SHOPFinance/dealReport'),
	//         meta: {
	//           title: '交易统计',
	//           roles: ['admin']
	//         }
	//       },
	//     ]
	// 	},

	// {
	// 	path: '/configure',
	// 	name: 'configure',
	// 	component: Layout,
	// 	redirect: '/SHOPConfigure/inquiryConfigure',
	// 	meta: {
	// 		title: '系统配置',
	// 		roles: ['admin'],
	// 		icon: "el-icon-s-tools"
	// 	},
	// 	children: [{
	// 			path: 'inquiryConfigure',
	// 			name: 'inquiryConfigure',
	// 			component: () =>
	// 				import('@/views/SHOPConfigure/inquiryConfigure'),

	// 			meta: {
	// 				title: '系统配置',
	// 				roles: ['admin']
	// 			}
	// 		},
	// 		// {
	// 		// 	path: 'guestConfigure',
	// 		// 	name: 'guestConfigure',
	// 		// 	component: () =>
	// 		// 		import('@/views/SHOPConfigure/guestConfigure'),

	// 		// 	meta: {
	// 		// 		title: '客情配置',
	// 		// 		roles: ['admin']
	// 		// 	}
	// 		// }
	// 	]

	// }
	//   {
	//     path: '/template',
	//     name: 'template',
	//     component: Layout,
	//     redirect: 'noRedirect',
	//     meta: {
	//       title: '测试',
	//       roles: ['admin'],
	//       icon: "el-icon-lock"
	//     },
	//     children: [{
	//       path: 'SingInputAndTable',
	//       name: 'SingInputAndTable',
	//       component: () =>
	//         import('@/views/template/SingInputAndTable'),
	//       meta: {
	//         title: '单行选择加表格',
	//         roles: ['admin']
	//       }
	//     },
	//       {
	//         path: 'MultipleInputAndOther',
	//         name: 'MultipleInputAndOther',
	//         component: () =>
	//           import('@/views/template/MultipleInputAndOther'),
	//         meta: {
	//           title: '多行选择其他样式',
	//           roles: ['admin']
	//         }
	//       },{
	//         path: 'DrawOne',
	//         name: 'DrawOne',
	//         component: () =>
	//           import('@/views/template/DrawOne'),
	//         meta: {
	//           title: '抽屉一',
	//           roles: ['admin']
	//         }
	//       },{
	//         path: 'DrawTwo',
	//         name: 'DrawTwo',
	//         component: () =>
	//           import('@/views/template/DrawTwo'),
	//         meta: {
	//           title: '抽屉二',
	//           roles: ['admin']
	//         }
	//       },{
	//         path: 'DialogOne',
	//         name: 'DialogOne',
	//         component: () =>
	//           import('@/views/template/DialogOne'),
	//         meta: {
	//           title: '弹出框',
	//           roles: ['admin']
	//         }
	//       },
	//       {
	//         path: 'ImportExportDown',
	//         name: 'ImportExportDown',
	//         component: () =>
	//           import('@/views/template/ImportExportDown'),
	//         meta: {
	//           title: '导入,导出,本地下载模板',
	//           roles: ['admin']
	//         }
	//       },
	//       {
	//         path: 'EchartRank',
	//         name: 'EchartRank',
	//         component: () =>
	//           import('@/views/template/EchartRank'),
	//         meta: {
	//           title: '排名',
	//           roles: ['admin']
	//         }
	//       },
	//       {
	//         path: 'EchartBar',
	//         name: 'EchartBar',
	//         component: () =>
	//           import('@/views/template/EchartBar'),
	//         meta: {
	//           title: '柱状图',
	//           roles: ['admin']
	//         }
	//       },
	//       {
	//         path: 'EchartPie',
	//         name: 'EchartPie',
	//         component: () =>
	//           import('@/views/template/EchartPie'),
	//         meta: {
	//           title: '饼状图',
	//           roles: ['admin']
	//         }
	//       },
	//       // {
	//       //   path: 'therapy',
	//       //   name: 'therapy',
	//       //   component: () =>
	//       //     import('@/views/template/therapy'),
	//       //   meta: {
	//       //     title: '动态表头',
	//       //     roles: ['admin']
	//       //   }
	//       // },
	//       {
	//         path: 'ProductIntroduce',
	//         name: 'ProductIntroduce',
	//         component: () =>
	//           import('@/views/template/ProductIntroduce'),
	//         meta: {
	//           title: '产品介绍',
	//           roles: ['admin']
	//         }
	//       },{
	//         path: 'TTTest',
	//         name: 'TTTest',
	//         component: () =>
	//           import('@/views/template/TTTest'),
	//         meta: {
	//           title: 'TTTest',
	//           roles: ['admin']
	//         }
	//       },]
	//   },
]


const createRouter = () => new Router({
	// mode: 'history', // require service support
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter()
// router.addRoutes(dynamicRouter)

export const routesMap = parseMap(asyncRoutes)

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}


export function parseMap(arr) {
	let routesMap = {}

	function flat(arr) {
		return arr.reduce((pre, cur) => {

			if (cur.name) {
				if(cur.name == 'dashboard'){
					if(!routesMap['dashboard']){
						routesMap['dashboard'] = cur
					}
				}else{
					routesMap[cur.name] = cur
				}
				
			}
			return pre.concat(Array.isArray(cur.children) ? flat(cur.children) : cur)
		}, [])
	}

	flat(arr)
	console.log(routesMap)
	return routesMap
}
export default router
