<template>
	<div class="menu-bar-container">
		<div class="menu-bar-item">
			<el-button type="text"
								 class="choice-channel"
								 :class="{'is-active': this.channel === '互联网医院'}"
								 @click="handleInternetHospital"
								 v-if="isActiveMenuBar.includes('互联网医院')">
				<i class="iconfont icon-hulianwangyiyuan item-icon" :class="{'is-active-color': this.channel === '互联网医院'}"></i>
				<span class="item-title" :class="{'is-active-color': this.channel === '互联网医院'}">互联网医院</span>
			</el-button>
		</div>
		
		<div class="menu-bar-item">
			<el-button type="text"
								 class="choice-channel"
								 :class="{'is-active': this.channel === '慢病管理'}"
								 @click="handleCdm"
								 v-if="isActiveMenuBar.includes('慢病管理')">
				<i class="iconfont icon-wenzhenjilu item-icon" :class="{'is-active-color': this.channel === '慢病管理'}"></i>
				<span class="item-title" :class="{'is-active-color': this.channel === '慢病管理'}">慢病管理</span>
			</el-button>
		</div>
		<div class="menu-bar-item">
			<el-button type="text"
								 class="choice-channel"
								 :class="{'is-active': this.channel === '三方药事'}"
								 @click="handleThird"
								 v-if="isActiveMenuBar.includes('三方药事')">
				<i class="iconfont icon-yaoshiduan item-icon" :class="{'is-active-color': this.channel === '三方药事'}"></i>
				<span class="item-title" :class="{'is-active-color': this.channel === '三方药事'}">三方药事</span>
			</el-button>
		</div>
		<div class="menu-bar-item">
			<el-button type="text"
								 class="choice-channel"
								 :class="{'is-active': this.channel === '系统管理'}"
								 @click="handleSystemManage"
								 v-if="isActiveMenuBar.includes('系统管理')">
				<i class="iconfont icon-xitongshezhi item-icon" :class="{'is-active-color': this.channel === '系统管理'}"></i>
				<span class="item-title" :class="{'is-active-color': this.channel === '系统管理'}">系统管理</span>
			</el-button>
		</div>
		<div class="menu-bar-item">
			<el-button type="text"
								 class="choice-channel"
								 :class="{'is-active': this.channel === '商户管理'}"
								 @click="handleSupply"
								 v-if="isActiveMenuBar.includes('商户管理')">
				<i class="iconfont icon-shanghuguanli item-icon" :class="{'is-active-color': this.channel === '商户管理'}"></i>
				<span class="item-title" :class="{'is-active-color': this.channel === '商户管理'}">商户管理</span>
			</el-button>
		</div>
	
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "index",
  computed: {
    ...mapGetters(['isActiveMenuBar'])
  },
	created () {
    this.channel = this.isActiveMenuBar[0]
  },
  data () {
    return {
      channel: ''
    }
  },
  methods: {
    handleInternetHospital () {
      this.$store.dispatch('user/getInfo', ['internetHospitalConstantRoutes', 'internetHospitalBaseAsyncRoutes'])
      this.channel = '互联网医院'
    },
    handleCdm () {
      this.$store.dispatch('user/getInfo', ['cdmConstantRoutes', 'cdmAsyncRoutes'])
      this.channel = '慢病管理'
    },
    handleThird () {
      this.$store.dispatch('user/getInfo', ['supplyConstantRoutes', 'supplyAsyncRoutes'])
      this.channel = '三方药事'
    },
    handleSystemManage () {
      this.$store.dispatch('user/getInfo', ['baseConstantRoutes', 'baseAsyncRoutes'])
      this.channel = '系统管理'
    },
    handleSupply () {
      this.$store.dispatch('user/getInfo', ['supplyConstantRoutes', 'supplyAsyncRoutes'])
      this.channel = '商户管理'
    }
  }
}
</script>

<style scoped lang="scss">
	.menu-bar-container {
		display: flex;
		align-items: center;
		justify-content: left;
		margin-left: 70px;
		height: 46px;
		
		.menu-bar-item {
			margin-right: 25px;
			
			/deep/ .el-button {
				color: #8f8f8f;
				
				&:hover {
					color: #2362FB;
				}
			}
			
			.item-icon {
				margin-right: 4px;
			}
			
			.item-title {
				font-weight: bold;
			}
		}
	}
	
	.choice-channel {
		padding-bottom: 3px;
		color: #444;
		font-weight: bold;
		font-size: 14px;
		border: 0 transparent;
		border-bottom: 3px solid transparent;
	}
	
	.is-active {
		color: #2362fb;
		border: 0 transparent;
		border-bottom: 3px solid #2362fb;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	
	.is-active-color {
		color: #2362fb;
	}
</style>
