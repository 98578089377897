const CONFIG = {
    // 开发环境配置 https://api.hngegejk.cn/
    development: {
        assetsPath: '/static', 					        // 静态资源路径
        baseUrl: 'https://test.hngegejk.cn/',             // 后台接口请求地址
        // baseUrl: 'https://cdm.hngegejk.cn/',             // 后台接口请求地址
        qiniuUrl: 'https://pic.hngegejk.cn/',		       // 七牛云图片地址
        hostUrl: '',  	  							   // H5地址(前端运行地址)
        websocketUrl: '', 							   // websocket服务端地址
        weixinAppId: '' 					           // 微信公众号appid
    },
    // 生产环境配置
    production: {
        assetsPath: '/static', 						  // 静态资源路径
        //baseUrl: 'https://api.hngegejk.cn/', 	  		  // 后台接口请求地址
		baseUrl: 'https://cdm.hngegejk.cn/', 	  		  // 后台接口请求地址
		
        qiniuUrl: 'https://pic.hngegejk.cn/',	  		  // 七牛云图片地址
        hostUrl: '', 	  							  // H5地址(前端运行地址)
        websocketUrl: '', 							  // websocket服务端地址
        weixinAppId: ''   							  // 微信公众号appid
    }
};

export default CONFIG[process.env.NODE_ENV];
