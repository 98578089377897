import request from '@/utils/request'

export function login(data) {
  return request({
    // url: '/admin/login',
    url: '/admin/loginAuth',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    // url: '/admin/authorizations',
    url: 'admin/user-info',
    method: 'get'
  })
}

// 账户列表
export function getAccountList(query) {
  return request({
    // url: '/admin/users',
    url: 'admin/users',
    method: 'get',
    params: query
  })
}
// 添加账户
export function postAccountAdd(data) {
  return request({
    url: 'admin/users',
    method: 'post',
    data: data
  })
}

// 修改账户
export function putAccount(id, data) {
  return request({
    url: `admin/users/${id}`,
    method: 'put',
    data: data
  })
}

// 修改账户状态
export function putAccountStatus(id, data) {
  return request({
    url: `admin/users/status/${id}`,
    method: 'put',
    data: data
  })
}

// 更新账户密码
export function putAccountPwd(id, data) {
  return request({
    url: `admin/users/updatePwd/${id}`,
    method: 'put',
    data: data
  })
}

// 删除账号
export function delAccount(id) {
  return request({
    url: `admin/users/${id}`,
    method: 'DELETE',
  })
}

// 删除角色
export function delRole(id) {
  return request({
    // url: '/admin/users',
    url: `admin/roles/${id}`,
    method: 'DELETE',
  })
}


// 角色列表
export function getRolesList(params) {
  return request({
    // url: '/admin/users',
    url: 'admin/roleLists',
    method: 'get',
    params: params
  })
}

// 客服列表
export function getStaffList(params) {
  return request({
    url: 'admin/users/getStaffs',
    method: 'get',
    params: params
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export function Userlogout() {
  return request({
    url: '/admin/authorizations',
    method: 'Delete',
    data:{}
  })
}

